@import "../colors";
@import "../general";

.react-select-container {

  .react-select__control {
    border: 1px solid $blue;
    border-radius: 10px;
    background: $white;
    text-wrap: nowrap;

    &.react-select__control--is-focused, &.react-select__control--is-focused {
      border-color: $blue;
      box-shadow: none;
    }

    &.react-select__control--is-disabled {
      background-color: $grey-3;
    }

    &:hover {
      border-color: $blue;
    }
  }

  .react-select__value-container {
    padding-left: 0.9375rem;

    * {
      color: $black-2;
    }

    .react-select__value-container--is-multi {

    }

    .react-select__value-container--has-value {

    }
  }

  .react-select__indicators {
    .react-select__indicator-separator {
      background-color: $blue;
    }

    .react-select__indicator {
      color: $blue;
      padding: 0.8125rem 0.9375rem;

      .react-select__dropdown-indicator {

      }
    }
  }

  //header select
  .react-select-head__control {
    border: none;
    background: transparent;

    &.react-select-head__control--is-focused, &.react-select-head__control--is-focused {
      border-color: $blue;
      box-shadow: none;
    }

    &:hover {
      border-color: $blue;
    }

    * {
      @include Rubik;
      cursor: pointer;
      font-size: 1rem;
      line-height: 1.188rem;
      letter-spacing: 0px;
      background: transparent;
      border: none;
      outline: none;
      color: rgba($light-grey-2, 0.8);
    }
  }

  .react-select-head__value-container {
    padding-left: 0.9375rem;

    * {
      @include Rubik;
      cursor: pointer;
      width: 100%;
      font-size: 1rem;
      line-height: 1.188rem;
      letter-spacing: 0px;
      background: transparent;
      border: none;
      outline: none;
      color: rgba($light-grey-2, 0.8);
    }

    .react-select-head__value-container--is-multi {

    }

    .react-select-head__value-container--has-value {

    }
  }

  .react-select-head__indicators {
    .react-select-head__indicator-separator {
      background-color: $blue;
    }

    .react-select-head__indicator {
      color: $blue;
      &:last-of-type {
        padding: 0.8125rem 0.9375rem 0.8125rem 5px;
      }

      .react-select-head__dropdown-indicator {

      }
    }
  }
}

.prefix {
  .react-select-container {
    .react-select__control {
      min-height: 45px;
      margin-top: -1px;
      margin-left: -1px;
      margin-right: -1px;
      min-width: 120px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .react-select__indicators {
      .react-select__indicator-separator {
      }

      .react-select__indicator {
        padding: 0;

        .react-select__dropdown-indicator {

        }
      }
    }
  }
}
