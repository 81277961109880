@import "libs/common/styles/colors";

.infoButton {
  border-radius: 50%;
  border: none;
  width: 20px;
  height: 20px;
  background: $pastel-pink;
  color: orange;
  cursor: pointer;
  font-size: 13px;
}
