@import "../colors";

.rdt_Table {

}
.rdt_TableRow {
  background-color: $white !important;
  border: none !important;
  &:hover {
    background: $light-blue !important;
  }
}
.rdt_TableCol, .rdt_TableCol_Sortable {
  font-size: 1rem;
  line-height: 1.188rem;
  font-weight: bold;
  letter-spacing: 0;
  color: $blue;
}

.rdt_TableCell {
  font-size: 1rem;
  line-height: 1.188rem;
  font-weight: 300;
  letter-spacing: 0;
  color: $black-2;
}
.rdt_TableHeader {

}
.rdt_TableFooter {

}
.rdt_TableHead {

}
.rdt_TableHeadRow {
  background-color: $white !important;
  border: none !important;
  border-bottom: 1px solid $light-grey !important;
}
.rdt_TableBody {

}
.rdt_ExpanderRow {

}
