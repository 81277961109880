@import "libs/common/styles/colors";
@import "libs/common/styles/general";
@import "../Modal/Modal.module.scss";

.alertContainer {
  @extend .modalContainer;

  .content {
    font-size: 1rem;
    font-weight: 500;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    button {
      margin-left: 1rem;
    }
  }
}
