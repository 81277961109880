@import "colors";
@import "components/dateRange";
@import "components/dataTable";
@import "components/reactSelect";
@import "components/reactToastify";
@import "general";
@import "components/map";

/*
SIDEBAR VARIABLES
*/
$sidebar-bg-color: $blue !default;
$sidebar-color: rgba($light-grey-2, 0.8) !default;
$sidebar-width: 285px !default;
$sidebar-collapsed-width: 0 !default;
$highlight-color: $light-grey-2 !default;
$submenu-bg-color: $blue !default;
$submenu-bg-color-collapsed: $blue !default;
$icon-bg-color: transparent !default;
$icon-size: 20px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
/*------------------*/

@import "react-pro-sidebar/dist/scss/styles";
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
html,
body {
  background: $light-grey;
  padding: 0;
  margin: 0;
  @include Rubik;

  #__next {
    height: 100vh;
    overflow: hidden;
  }

}

a {
  color: $blue;
  cursor: pointer;
  text-decoration: none;
}

* {
  box-sizing: border-box;

  /* width */
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $grey-2;
    border-radius: 10px;
  }

}

h1, h2, h3, h4, h5, h6 {
  @include Rubik;
  font-weight: bold;
  letter-spacing: 0;
  color: $black-2;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.2;
}

h3 {
  font-size: 1.25rem;
}

.sourceIcon {
  color: $white;
  background-color: $blue;
  width: 10px;
  height: 10px;
  padding: 5px;
  border-radius: 5px;
}

.tox-notifications-container {
  display: none !important;
}

.pro-sidebar {
  max-height: calc(100vh - 75px);
  @include tablet {
    position: absolute;
  }
}

.scrollDisabled {
  margin: 0 !important;
  height: 100% !important;
  overflow: hidden !important;
}

.overflowVisible {
  overflow: visible !important;
}

.green {
  color: $green;
}

.red {
  color: $red;
}
