@import "../colors";
@import "react-toastify/dist/ReactToastify.min.css";
@import "../general";

:root {
  --toastify-color-info: #3498DB; //$blue-5
  --toastify-color-success: #0BAF00; //$green
  --toastify-color-warning: #FEE356; //$yellow-2
  --toastify-color-error: #FE3E3E; //$red
}

.Toastify__toast-container--top-right {
  top: 75px;
}

.Toastify__toast {
  box-shadow: 0 3px 10px $shadow-grey-2;
  border-radius: 10px;
  padding-bottom: 0.625rem;
}

.Toastify__toast-body {
  @include Rubik;
  padding: 0.75rem 0.75rem 1.25rem;
  text-align: left;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  color: $black-2;
  overflow-wrap: anywhere;
}

.Toastify__progress-bar {
  height: 0.625rem;
}

.Toastify__toast-icon {
  display: grid;
  place-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border-width: 3px;
  border-style: solid;
  margin-right: 1.25rem;

  svg {
    height: 28px !important;
    width: 28px !important;
  }
}

.Toastify__toast--info {
  .Toastify__toast-icon {
    border-color: $blue-5;
    color: $blue-5;
  }
}

.Toastify__toast--success {
  .Toastify__toast-icon {
    border-color: $green;
    color: $green;
  }
}

.Toastify__toast--warning {
  .Toastify__toast-icon {
    border-color: $yellow-2;
    color: $yellow-2;
  }
}

.Toastify__toast--error {
  .Toastify__toast-icon {
    border-color: $red;
    color: $red;
  }
}
