@import "libs/common/styles/colors";
@import "libs/common/styles/general";

.head {
  margin-bottom: 10px;
  h2 {
    font-weight: bold;
    font-size: 1.563rem;
    line-height: 2.063rem;
    letter-spacing: 0;
    color: $black-2;
    text-transform: uppercase;
  }
  span {
    font-size: 0.938rem;
    line-height: 0.938rem;
    letter-spacing: 0;
    color: $black-2;
  }
}

.tosContent {
  //@include Roboto;
  background: $light-grey-3;
  font-size: 1.063rem;
  line-height: 1.438rem;
  letter-spacing: 0;
  color: $black-2;
  padding: 25px;
  width: 100%;
  max-height: 395px;
  height: max-content;
  overflow-y: scroll;
  overflow-wrap: anywhere;
}

.inputs {
  display: grid;
  grid-template-columns: auto max-content;
  justify-content: space-between;
  margin-top: 20px;
  align-items: flex-start;
}

.tosButton {
  //@include Roboto;
  width: 214px;
  height: 55px;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.625rem;
  letter-spacing: 0;
  color: $black-2;
  text-transform: uppercase;
}
