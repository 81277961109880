@import "../../responsive";

.page {
  height: 100vh;
  display: grid;
  place-items: center;
  padding: 150px 160px 85px;
  gap: 40px;
  overflow: scroll;
  @include large-desktop {
    gap: 100px;
  }
}

.container {
  display: grid;
  place-items: flex-start;
  width: 100%;
  gap: 70px;

  @include desktop {
    grid-template-columns: 1fr 1fr;
  }

  .formContainer {
    position: relative;

    .logo {
      position: absolute;
      transform: translate(0%, -240px);
      width: calc(100% - 4.5rem);
      display: grid;
      place-content: center;
    }


  }

  form {
    display: grid;
    gap: 15px;

    .submitButton {
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
    }
  }

}
