@import "../general";

#map {
  height: 70vh;

  .leaflet-top {
    &.leaflet-right {
      pointer-events: none;
    }
  }

  .zoneSelectContainer {
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 22px;

    select {
      padding-left: 10px;
      padding-right: 10px;
      font-weight: bold;
      font-size: 22px;
      border: none;
      cursor: pointer;
    }
  }

  .infoContainer {
    padding: 10px;
    border-radius: 5px;
    background: #fff;
    width: 100%;
    max-height: 41vh;
    overflow-y: auto;
    display: flex;
    height: max-content;
    flex-direction: column;
    flex-wrap: wrap;

    .toggleButton {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;

      i {
        transition: all ease-in-out 0.3s;
      }
    }

    &.hide {
      max-width: 20px;

      .toggleButton {
        i {
          transform: rotate(180deg);
        }
      }
    }

    > div {
      display: grid;
      grid-template-columns: max-content auto;
      align-items: center;

      .infoColor {
        margin: 10px;
        border-radius: 5px;
        width: 15px;
        height: 15px;
      }

      .zoneName {
        display: block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .buttonContainer {
    display: grid;
    grid-template-columns: max-content max-content;
    gap: 10px;

    button {
      @include button;
      width: max-content;

      &.cancel, &.delete {
      }

      &.delete {
        grid-column: span 2;

        &.hide {
          display: none;
        }
      }

      &.save {
      }

      &.hide {
        visibility: hidden;
      }
    }
  }

  g {
    path {
      transition: all ease-in-out 0.3s;
    }
  }

}

.full-field {
  flex: 400px;
  margin: 15px 0;
}

.slim-field-left {
  flex: 1 150px;
  margin: 15px 15px 15px 0px;
}

.slim-field-right {
  flex: 1 150px;
  margin: 15px 0px 15px 15px;
}

.my-button {
  background-color: #000;
  border-radius: 6px;
  color: #fff;
  margin: 10px;
  padding: 6px 24px;
  text-decoration: none;
}

.my-button:hover {
  background-color: #666;
}

.my-button:active {
  position: relative;
  top: 1px;
}

.pac-container {
  &.pac-logo {
    &:after {
      display: none;
    }
  }
}
