@import "libs/common/styles/colors";
@import "libs/common/styles/responsive";

.container {
  display: grid;
  grid-template-columns: min-content auto;
  height: calc(100vh - 75px);

  .content {
    overflow: hidden;
    height: 100%;
    @include tablet {
      width: 100vw;
    }
    .mainContent {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      overflow-wrap: anywhere;
      padding: 1rem;

      @include desktop {
        padding: 2.25rem;
      }
    }
  }
}

.loading {
  display: grid;
  place-content: center;
  height: 100vh;
  width: 100vw;
  justify-items: center;
  gap: 3rem;
  font-size: 2rem;
  color: $blue;
}
