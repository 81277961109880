@import "libs/common/styles/colors";
@import "libs/common/styles/general";
@import "libs/common/styles/responsive";

.modalContainer {
  display: none;
  place-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1009;
  overflow-y: scroll;
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: $black-2;
    opacity: 0.2;
    &.closeEnabled {
      cursor: pointer;
    }
  }
  .modal {
    @include card;
    z-index: 1;
    width: 50%;
    margin-top: 3rem;
    margin-bottom: 3rem;

    @include tablet {
      width: calc(100vw - 2rem);
      max-height: calc(100vh - 6rem);

      padding: 1rem;
    }

    .titleContainer {
      grid-template-columns: 1fr auto;
      justify-content: space-between;

      .closeButton {
        display: grid;
        place-items: center;
        border-radius: 50%;
        border: none;
        width: 2rem;
        height: 2rem;
        background: $light-grey;
        color: $black-2;
        cursor: pointer;
        transition: all ease 0.3s;
        &:hover {
          background: $blue;
          color: $white;
        }
      }
    }

  }

  &.open {
    //TODO Erhard create animation for reveal and close
    display: grid;
  }
}
